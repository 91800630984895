 jQuery(document).ready(function () {
     eqHeight();
     jQuery('.slider-section ul').bxSlider({
         pager: false,
         controls: false,
         auto: (jQuery('.slider-section ul').children().length < 2) ? false : true,
         touchEnabled: (jQuery('.slider-section ul').children().length < 2) ? false : true,
         adaptiveHeight: true,
         useCSS: false
     });


     jQuery(".site").on('click', function () {

         jQuery('body').removeClass('open-menu');
         jQuery('.toggle-menu').removeClass('change');
     });


     jQuery(".toggle-menu").on('click', function () {
         jQuery('body').toggleClass("open-menu");
     });


     jQuery('.toggle-menu, .main-navigation').on('click', function (event) {
         event.stopPropagation();
     });


     jQuery(".main-navigation ul li > a").on("click ", function (event) {

         if (!jQuery(this).parent().hasClass("open") && jQuery(this).parent().has("ul").length > 0) {
             event.preventDefault();
             event.stopPropagation();
             jQuery(this).parent().addClass("open").siblings().removeClass("open");
         }

     });

 });

 jQuery(window).load(function () {
     eqHeight();
 });

 jQuery(window).resize(function () {
     eqHeight();
 });

 function eqHeight() {
     equalheightnoRow('.footer-col');
     equalheight(' .properties-block');

 }

 equalheight = function (container) {
     if (jQuery(window).width() > 767) {

         var currentTallest = 0,
             currentRowStart = 0,
             rowDivs = [],
             $el,
             topPosition = 0;
         jQuery(container).each(function () {
             $el = jQuery(this);
             jQuery($el).height('auto');
             topPostion = $el.offset().top;
             if (currentRowStart != topPostion) {
                 for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                     rowDivs[currentDiv].innerHeight(currentTallest);
                 }
                 rowDivs.length = 0; // empty the array
                 currentRowStart = topPostion;
                 currentTallest = $el.innerHeight();
                 rowDivs.push($el);
             } else {
                 rowDivs.push($el);
                 currentTallest = (currentTallest < $el.innerHeight()) ? ($el.innerHeight()) : (currentTallest);
             }
             for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                 rowDivs[currentDiv].innerHeight(currentTallest);
             }
         });
     } else {
         jQuery(container).height('auto');
     }
 };

 equalheightnoRow = function (container) {
     if (jQuery(window).width() > 767) {
         var currentTallest = 0,
             currentRowStart = 0,
             rowDivs = [],
             jQueryel;

         jQuery(container).each(function () {
             jQueryel = jQuery(this);
             jQuery(jQueryel).innerHeight('auto');
             rowDivs.push(jQueryel);
             currentTallest = (currentTallest < jQueryel.innerHeight()) ? (jQueryel.innerHeight()) : (currentTallest);

             for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                 rowDivs[currentDiv].innerHeight(currentTallest);
             }
         });
     } else {
         jQuery(container).height('auto');
     }
 };

 function myFunction(x) {
     x.classList.toggle("change");
 }
